import { tuiCoreIcons } from '@taiga-ui/icons';

export const ICON_MAPPER: Record<string, string> = {
  ...tuiCoreIcons,
  knkAddBox: 'add-box',
  knkAdd: 'add',
  knkApi: 'api',
  knkAi: 'ai',
  knkCalendarMonth: 'calendar-month',
  knkCall: 'call',
  knkCertificate: 'certificate',
  knkCheck: 'check',
  knkChevronLeft: 'chevron-left',
  knkChevronRight: 'chevron-right',
  knkChoose: 'choose',
  knkClock: 'clock',
  knkClose: 'close',
  knkCommunication: 'communication',
  knkCondition: 'condition',
  knkContentCopy: 'content-copy',
  knkCoupon: 'coupon',
  knkCustomerCard: 'customer-card',
  knkDelete: 'delete',
  knkDocument: 'document',
  knkDownload: 'download',
  knkDragIndicator: 'drag-indicator',
  knkEdit: 'edit',
  knkEvent: 'event',
  knkExpandLess: 'expand-less',
  knkExpandMore: 'expand-more',
  knkExtension: 'extension',
  knkFilter: 'filter',
  knkFlow: 'flow',
  knkFolder: 'folder',
  knkFormula: 'formula',
  knkGift: 'gift',
  knkHelp: 'help',
  knkHttp: 'http',
  knkJsCode: 'js-code',
  knkLibraryAdd: 'library-add',
  knkListAlt: 'list-alt',
  knkList: 'list',
  knkLoading: 'loading',
  knkLockOpen: 'lock-open',
  knkLock: 'lock',
  knkLogo: 'logo',
  knkMoreVertical: 'more-vertical',
  knkPeople: 'people',
  knkPreview: 'preview',
  knkRefresh: 'refresh',
  knkReward: 'reward',
  knkSave: 'save',
  knkSearch: 'search',
  knkSegments: 'segments',
  knkSettingsVertical: 'settings-vertical',
  knkSettings: 'settings',
  knkSql: 'sql',
  knkTech: 'tech',
  knkUndefinedIcon: 'undefined-icon',
  knkUploadFile: 'upload-file',
  knkWait: 'wait'
};
